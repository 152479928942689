<template>
  <div>
  	<app-drawer_menu drawer_menu_title="Grade"></app-drawer_menu>
    <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
	    ></v-progress-linear>
	  	<v-tabs
	      background-color="white"
	      color="fourth"
	      v-if ="access.read === 1"
	    >
	      <v-tab
	      	id       ="tabform"
	       	href   	 ="#tab-form"
	       	@click 	 ="FirstLoad"
	      >
	       	Form
	    	</v-tab>
	      <v-tab 
	       	href    ="#tab-list"
	       	@click  ="List"
	      >
	    	List
	    	</v-tab>
        <v-tab-item
            value  ="tab-form"
        >
			    <v-form enctype="multipart/form-data" ref="form_grade" lazy-validation>
			      <v-row class="mx-2 ma-1">
              <v-col cols="12" sm="12" md="12">
                <v-btn
                  color		          ="tertiary"
                  class			        ="white--text"
                  :disabled         ="access.add === 0 ? true:false" 
                  rounded
                  @click 	          ="Add" 
                  small
                >
                  <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                </v-btn> &nbsp;
                <v-btn
                  color		    ="primary"
                  class		    ="white--text"
                  :disabled 	="access.add === 1 || access.edit === 1 ? false:true"
                  rounded
                  @click 	  	="Confirm('write','')" 
                  small
                >
                  <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                </v-btn>
              </v-col>
              <!-- ID -->
              <v-col cols="12" sm="4" md="2">
                <v-text-field 
                v-model 	  	="form.grade_id"
                required 
                :rules 	  	    ="form.grade_id_rules"
                :readonly       ="form.grade_id_readonly"
                :counter        ="maxinput.id"
                :maxlength      ="maxinput.id"
                label       	  ="ID *"
                placeholder 	  ="ID"
                id 		      	  ="grade_id"
                @keyup.enter 	  ="Confirm('write','')"
                >
                </v-text-field>
              </v-col>
              <!-- Description -->
              <v-col cols="12" sm="8" md="4">
                <v-text-field 
                v-model 	  	="form.grade_desc"
                required 
                :rules 	  	    ="form.grade_desc_rules"
                label       	="Description"
                placeholder 	="Description"
                id 			      ="grade_desc"
                @keyup.enter 	="Confirm('write','')"
                >
                </v-text-field>
              </v-col>
              <!-- Basic Salary -->
              <v-col cols="12" sm="3" md="2">
                <vuetify-money
                  v-model         ="form.grade_basicsalarymin"
                  label           ="Basic Salary Min"
                  placeholder     ="Basic Salary Min"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
               <!-- Basic Salary -->
               <v-col cols="12" sm="3" md="2">
                <vuetify-money
                  v-model         ="form.grade_basicsalarymax"
                  label           ="Basic Salary Max"
                  placeholder     ="Basic Salary Max"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- Meal -->
              <v-col cols="12" sm="3" md="2">
                <vuetify-money
                  v-model         ="form.grade_meal"
                  label           ="Meal Allowance"
                  placeholder     ="Meal Allowance"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- transportation -->
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model         ="form.grade_transportation"
                  label           ="Transportation Allowance"
                  placeholder     ="Transportation Allowance"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- Position -->
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model         ="form.grade_position"
                  label           ="Position Allowance"
                  placeholder     ="Position Allowance"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- Car -->
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model         ="form.grade_car"
                  label           ="Car Allowance"
                  placeholder     ="Car Allowance"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!--Mobile -->
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model         ="form.grade_mobile"
                  label           ="Internet Allowance"
                  placeholder     ="Internet Allowance"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- ospocket -->
              <v-col cols="12" sm="2" md="2">
                <vuetify-money
                  v-model         ="form.grade_ospocket"
                  label           ="Pocket Money"
                  placeholder     ="Pocket Money"
                  backgroundColor ="numeric"
                  :options        ="format_amount"
                  :properties     ="properties"
                  :valueWhenIsEmpty ="formatempty"
                  @keyup.enter 	  ="Confirm('write','')"
                >
                </vuetify-money>
              </v-col>
              <!-- Actived -->
              <v-col cols="12" sm="2" md="2" class="mt-n5">
                <label class="caption">Active Status</label>
                <v-switch
                  v-model	  ="form.grade_actived"
                  color  	  ="primary"
                  id 		    ="form.grade_actived"
                  :label 	  ="form.grade_actived !== true ? '' : 'Active'" 
                ></v-switch>
              </v-col>
			      </v-row>
            <v-row class="mx-2">
              <v-col cols="12" sm="5" md="5">
				      <div v-show="url_type !== 'add' ? true:false ">
                <v-checkbox v-model="cont" label="Show Info"></v-checkbox>
                  <v-banner v-model="cont" single-line transition="slide-y-transition">
<pre>
Created Date 	 : {{form.grade_cdate}}
Created User 	 : <span class="cursor" @click="ShowUsers(form.grade_cuser)">{{form.grade_cuser}}</span>
Last Update Date : {{form.grade_ldate}}
Last Update User : <span class="cursor" @click="ShowUsers(form.grade_luser)">{{form.grade_luser}}</span>
</pre>
                </v-banner>
			        </div>
              </v-col>
            </v-row>
         	</v-form>
		  	</v-tab-item>
		  	<v-tab-item
			    value="tab-list"
        >
        	<template>
			      <v-card-title
              class="ml-n4"
            >
              <v-btn 
					  	:color 	="selected.length === items.length ? 'secondary':''"
					  	@click	="SelectAllPage"
					  	class	="mr-2"
					  	:title="$vars.V('txt_select_all_page')"
					  	small
					  	>{{$vars.V('txt_select_all_page')}}
              </v-btn>
              <v-btn 
                color     ="error"
                title 	  ="delete selected"
                @click 	  ="Confirm('multidelete','')"
                class	    ="mr-2"
                v-if  	  ="selected.length > 0"
                :disabled ="access.delete === 0 ? true:false"
                small>
                  <v-icon>{{$vars.V('icon_delete')}}</v-icon>
              </v-btn>
              <v-btn 
                color  ="primary"
                title  ="search"
                v-if   ="btn_search === false"
                @click ="btn_search = true"
                small>
                  <v-icon>{{$vars.V('icon_search')}}</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
			        v-model="search"
			        append-icon="search"
			        @click:append="btn_search = false"
			        label="Search"
			        single-line
			        hide-details
			        v-if ="btn_search"
			      	></v-text-field>
			      </v-card-title>
            <v-col cols="12" sm="12" md="12">
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="items"
              :single-select="singleselect"
              :search="search"
              item-key="grade_id"
              show-select
              class="elevation-1"
              :footer-props="{
                showFirstLastPage: true,
                firstIcon: 'first_page',
                lastIcon: 'last_page',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right'
              }"
              dense
              :loading  ="loading.list"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.grade_basicsalarymin="{ item }">
                {{ $functions.NewFormatNumber(item.grade_basicsalarymin,0) }} - {{  $functions.NewFormatNumber(item.grade_basicsalarymax,0) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="Edit(item.grade_id)"
                  :disabled ="access.edit === 0 ? true:false"
                >
                  {{$vars.V('icon_edit')}}
                </v-icon>
                <v-icon
                  small
                  @click="Confirm('delete',item.grade_id)"
                  :disabled ="access.delete === 0 ? true:false"
                >
                  {{$vars.V('icon_delete')}}
                </v-icon>
              </template>
			      </v-data-table>
            </v-col>
			    </template>
		  	</v-tab-item>
  	  </v-tabs>
    <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth">
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-snackbar
	  v-model	="snackbar.dialog"
      :timeout	="snackbar.timeout"
      color		="fourth"
      rounded	="pill"
      absolute
      top
      style 	="z-index: 9999;"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
      <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
  	  </template>
  </v-snackbar>
    <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
        <v-card :color="Variable('confirm',4)">
            <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
            <v-card-text>
                
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn id="dialog" :color="Variable('confirm',1)"
                    :disabled   = "confirm.text === 'Ok' ? false:true"
                  @click      = "Loading">{{confirm.text}}</v-btn>
                <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- Dialog Users -->
	<app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
  </div>
</template>
<script>
export default {
  data: () => ({
    url_type: 'add',
    access: {
      read: 0,
      add: 0,
      edit: 0,
      delete: 0,
    },
    format_money: {
      decimal: ',',
      thousands: '.',
      precision: 0,
    },
    maxinput:{
      id:3,
    },
    loading: {
      page : true,
      list : false
    },
    snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
    cont: false,
    confirm: {
			dialog: false,
			title: '',
			text: "Ok",
		},
    singleselect: false,
    selected: [],
    search: '',
    headers: [
      {
        text: 'ID',
        align: 'left',
        value: 'grade_id',
      },
      { text: 'Description', value: 'grade_desc' },
      { text: 'Basic Salary', value: 'grade_basicsalarymin' },
      { text: 'Actived', value: 'grade_actived' },
      { text: 'Actions', value: 'action', sortable: false }
    ],
    remarks: {
			feedback:'',
			dialog: false,
		},
    items: [],
    feeds: [],
    accessmenu: [],
    btn_search: true,
    form: {
      grade_id: '',
      grade_id_rules: [v => !!v || 'Please fill ID'],
      grade_id_readonly: false,
      grade_desc: '',
      grade_desc_rules: [v => !!v || 'Please fill Description'],
      grade_basicsalarymin: 0,
      grade_basicsalarymax: 0,
      grade_meal: 0,
      grade_transportation: 0,
      grade_position: 0,
      grade_car: 0,
      grade_mobile: 0,
      grade_ospocket: 0,
      grade_osmeal: 0,
      grade_othd3hour: 0,
      grade_othd4hour: 0,
      grade_othd6hour: 0,
      grade_otwdmealmin: 0,
      grade_otwdmealmax: 0,
      grade_actived: true,
      display_users: '',
    },
		dialog: {
			users: false
		},
    format_amount: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 0
    },
    format_rate: {
      locale: "id-ID",
      prefix: "",
      suffix: "",
      length: 15,
      precision: 2
    },

    // Format Properties
    properties: {
      reverse: true
    },
    formatempty: "0",
    }),
  	created () {
    	this.FirstLoad()
    },
  	methods: {
  		FirstLoad () {
        this.Access()
  			setTimeout(function(){
          document.getElementById('grade_id').focus()
        }, 500)
    },
    Access () {
			let modul = 'grade'
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: 'menu_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
			.then(response => {
				let priv = response.data
        if (priv.length > 0) {
            if (priv[0].feedback !== 'Y') {
              this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
              this.remarks.dialog = true
              this.remarks.feedback = priv[0].feedback
            } else {
              this.access.read  	  = priv[0].usersmenu_read
              this.access.add   	  = priv[0].usersmenu_add
              this.access.edit    	= priv[0].usersmenu_edit
              this.access.delete  	= priv[0].usersmenu_delete
              if (priv[0].usersmenu_read === 0) {
        this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
        }
            }
        } else {
            this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
        }
        this.loading.page = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 0)
				this.loading.page = false
			})
		},
		//This function use for give feedback when user has no access with some feature
    SelectAllPage () {
      this.selected = this.selected === this.items ? [] : this.items
    },
    Check (value) {
      this.selected = value
    },
    List () {
        this.loading.list = true
        let formdata = {
            grade_id: '',
            grade_desc: '',
            order_by: 'grade_id',
            order_type: 'ASC',
            limit: this.limit,
        }
        let param 	   = this.$functions.ParamPOST(formdata)
        this.$axios.post(this.$functions.UrlPOST('apiListGrade'),param,{
            headers: {
                'Content-Type': 'text/plain; charset=ISO-8859-1'
            }
        })
        .then(response => {
            let feedback = response.data
            if (feedback.length > 0) {
                if (feedback[0].feedback !== 'Y') {
                    this.SnackBar(true, 'error', feedback[0].feedback, 0)
                } else {
                    this.items = feedback
                }
            } else {
                this.items = feedback
                this.SnackBar(true, 'error', this.$functions.NoData(), 0)
            }
            this.loading.list = false
        })
        .catch(e => {
            this.SnackBar(true, 'error', e, 3000)
            this.loading.list = false
        })
    },
    //Pop Up message for validating (Ok or Cancel) your proccess
    Confirm (flag, id) {
      if (flag === 'write') {
        if (this.$refs.form_grade.validate()) {
          this.confirm.dialog   = true
          this.confirm.title    = 'Save'
          setTimeout(function(){
            document.getElementById('dialog').focus()
          }, 500)
        }
      } else if (flag === 'delete') {
        this.url_type 		    = 'delete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ id + '`?'
        this.grade_id 	      = id
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      } else if (flag === 'multidelete') {
        this.url_type 		    = 'multidelete'
        this.confirm.dialog   = true
        this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
        setTimeout(function(){
          document.getElementById('dialog').focus()
        }, 500)
      }
    },  
    //Function to request insert data to table grade 'The API will validating your data, include the user who process the data' (go to APIWritegrade with url_type ="add") 
    Add () {
      this.url_type 		 		            = 'add'
      this.form.grade_id 	 	            = ''
      this.form.grade_desc 	 	          = ''
      this.$refs.grade_meal.$el.getElementsByTagName('input')[0].value = 0
      this.form.grade_actived 	        = true
      this.form.grade_id_readonly       = false
      setTimeout(function(){
        document.getElementById('grade_id').focus()
      }, 500)
    },
    Edit (id) {
			this.loading.list = true
			let formdata = {
				grade_id: id,
				grade_desc: '',
				order_by: 'grade_id',
				order_type: 'ASC',
				limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListGrade'),param,{
		        headers: {
		            'Content-Type': 'text/plain; charset=ISO-8859-1'
		        }
			})
      .then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
	       		document.getElementById('tabform').click()
	       		this.url_type 			            = 'edit'
	       		this.form.grade_id   	          = feedback[0].grade_id
            this.form.grade_desc 	          = feedback[0].grade_desc
            this.form.grade_basicsalarymin  = feedback[0].grade_basicsalarymin
            this.form.grade_basicsalarymax  = feedback[0].grade_basicsalarymax
            this.form.grade_meal            = feedback[0].grade_meal
            this.form.grade_transportation  = feedback[0].grade_transportation
            this.form.grade_position        = feedback[0].grade_position
            this.form.grade_car             = feedback[0].grade_car
            this.form.grade_mobile          = feedback[0].grade_mobile
            this.form.grade_otwdmealmin     = feedback[0].grade_otwdmealmin
            this.form.grade_otwdmealmax     = feedback[0].grade_otwdmealmax
            this.form.grade_othd3hour       = feedback[0].grade_othd3hour
            this.form.grade_othd4hour       = feedback[0].grade_othd4hour
            this.form.grade_othd6hour       = feedback[0].grade_othd6hour
            this.form.grade_osmeal          = feedback[0].grade_osmeal
            this.form.grade_ospocket        = feedback[0].grade_ospocket
            this.form.grade_actived         = this.$functions.TrueOrFalse(feedback[0].grade_actived)
            this.form.grade_cdate		        = feedback[0].grade_cdate
            this.form.grade_cuser		        = feedback[0].grade_cuser
            this.form.grade_ldate		        = feedback[0].grade_ldate
            this.form.grade_luser		        = feedback[0].grade_luser
            this.form.grade_id_readonly     = true
            setTimeout(function(){
              document.getElementById('grade_desc').focus()
            }, 500)
						setTimeout(function(){
	         		document.getElementById('grade_desc').focus()
	       		}, 500)
		  		} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback[0].feedback
				}
			} else {
				this.SnackBar(true, 'error', feedback, 0)
			}
			this.loading.list = false
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
				this.loading.list 		= false
			})
		},
    Delete (id) {
			let formdata = {
				url_type: 'delete',
				grade_id: id,
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiDeleteGrade'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Delete Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            this.selected 			 = []
						this.List()
	  			} else {
						this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
						this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog 	= true
				this.remarks.feedback 	= e
			})
    },
    MultiProcess () {
			let multiData = JSON.stringify(this.selected)
			let formdata = {
				url_type: this.url_type,
				multi_data: multiData,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGrade'),param,{
				headers: {
					'Content-Type': 'text/plain; charset=ISO-8859-1'
				}
			})
			.then(response => {
				let feedback = response.data
				if (feedback[0].feedback === 'Y') {
					let feed
					if (feedback[0].feedback_failed == 0){
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
					} else {
						this.remarks.feedback = feedback[0].feedback_failed_remarks
						feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
						this.remarks.dialog = true
					}
					this.SnackBar(true, 'success', feed, 0)
					this.confirm.dialog  	 = false
					this.confirm.text 	 	 = 'Ok'
					this.selected 			 = []
					this.List()
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog 	= true
					this.remarks.feedback 	= feedback[0].feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
	  },
    Loading () {
      this.confirm.text 	 = 'Loading...'
      if (this.url_type === 'add' || this.url_type === 'edit') {
        this.Write()
      } else if (this.url_type === 'delete') {
        this.Delete(this.grade_id)
      } else if (this.url_type === 'multidelete') {
        this.MultiProcess('delete')
      }
    },
    Write () {
      let formdata = {
        url_type: this.url_type,
        grade_id: this.form.grade_id,
        grade_desc: this.form.grade_desc,
        grade_actived:  this.$functions.ActivedFlag(this.form.grade_actived),
        grade_basicsalarymin: String(this.form.grade_basicsalarymin),
        grade_basicsalarymax: String(this.form.grade_basicsalarymax),
        grade_meal: String(this.form.grade_meal),
        grade_transportation: String(this.form.grade_transportation),
        grade_position: String(this.form.grade_position),
        grade_car: String(this.form.grade_car),
        grade_mobile: String(this.form.grade_mobile),
        grade_otwdmealmin: String(this.form.grade_otwdmealmin),
        grade_otwdmealmax: String(this.form.grade_otwdmealmax),
        grade_othd3hour: String(this.form.grade_othd3hour),
        grade_othd4hour: String(this.form.grade_othd4hour),
        grade_othd6hour: String(this.form.grade_othd6hour),
        grade_osmeal: String(this.form.grade_osmeal),
        grade_ospocket: String(this.form.grade_ospocket),
			}
      let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteGrade'),param,{
        headers: {
          'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback === 'Y') {
            this.SnackBar(true, 'success', 'Save Success', 3000)
            this.confirm.dialog  	 = false
            this.confirm.text 	 	 = 'Ok'
            if (this.url_type === 'add') {
              this.form.grade_cuser  	= feedback[0].feedback_users_id
              this.form.grade_cdate  	= feedback[0].feedback_users_date
            }
            this.form.grade_luser  		= feedback[0].feedback_users_id
            this.form.grade_ldate  		= feedback[0].feedback_users_date
            this.url_type 			 	= 'edit'
		  			} else {
            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
            this.remarks.dialog 	= true
						this.remarks.feedback 	= feedback[0].feedback
					}
				} else {
					this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
					this.remarks.dialog = true
					this.remarks.feedback = feedback
				}
			})
			.catch(e => {
				this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
				this.remarks.dialog = true
				this.remarks.feedback = e
			})
		},
	  SnackBar (dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout
      }
      if (color === 'error') {
        this.confirm.dialog 	 = false
        this.confirm.text 	 	 = 'Ok'
      }
    },
    ShowUsers (id) {
			this.dialog.users = true
			this.form.display_users = id
		},
		Variable (flag, position) {
			return this.$functions.Variable(flag,position)
		}
	}
}
</script>