<template>
    <div>
        <app-drawer_menu drawer_menu_title="Absence"></app-drawer_menu>
            <v-progress-linear
            indeterminate
            color="fourth"
            rounded
            v-if="loading.page"
          ></v-progress-linear>
            <v-tabs
            background-color="white"
            color="fourth"
            v-if = "access.read === 1"
          >
              <v-tab
                  id       = "tabform"
                  href   	 ="#tab-form"
                  @click 	 = "FirstLoad"
              >
                  {{$vars.V('txt_form')}}
              </v-tab>
              <v-tab 
                  href="#tab-list"
                  @click = "List"
              >
                  {{$vars.V('txt_list')}}
              </v-tab>
              <v-tab-item
                  value  ="tab-form"
              >
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="8"
                  class="mb-n5"
                >
              <v-card class="mb-12" outlined>
              <v-form enctype="multipart/form-data" ref="form_absence" lazy-validation>
              <v-row class="mx-2 ma-1">
                <!-- ID -->
                 <v-col cols="12" sm="3" md="3">
                    <v-text-field
                    v-model 	  	  ="form.absence_id"
                    required
                    :rules 	  	      ="form.absence_id_rules"
                    :readonly         ="form.absence_id_readonly"
                    :background-color ="form.absence_id_readonly ? 'readonly' : ''"
                    :maxlength 	      ="maxinput.id"
                    :counter		  ="maxinput.id"
                    label       	  ="ID *"
                    placeholder 	  ="ID"
                    id 			      ="absence_id"
                    @keyup.enter 	  ="Confirm('write','')"
                    >
                    </v-text-field>
                 </v-col>
                 <!-- Date -->
                 <v-col cols="12" sm="3" md="3">
                    <v-text-field
                    v-model 	  	="form.absence_date"
                    required
                    :rules          ="form.absence_date_rules"
                    label       	="Date *"
                    placeholder 	="Date"
                    id 			    ="absence_date"
                    @keyup.enter 	="Confirm('write','')"
                    :readonly       ="true"
                    >
                    </v-text-field>
                 </v-col>
                 <!-- Time In -->
                 <v-col cols="12" sm="3" md="3">
                    <v-text-field
                    v-model 	  	="form.absence_timein"
                    required
                    :rules          ="form.absence_timein_rules"
                    label       	="Time In *"
                    placeholder 	="Time In"
                    id 			    ="absence_timein"
                    @keyup.enter 	="Confirm('write','')"
                    :readonly       ="true"
                    >
                    </v-text-field>
                 </v-col>
                 <!-- Time Out -->
                 <v-col cols="12" sm="3" md="3">
                    <v-text-field
                    v-model 	  	="form.absence_timeout"
                    required
                    :rules          ="form.absence_timeout_rules"
                    label       	="Time Out *"
                    placeholder 	="Time Out"
                    id 			    ="absence_timeout"
                    @keyup.enter 	="Confirm('write','')"
                    :readonly       ="true"
                    >
                    </v-text-field>
                 </v-col>
                 <!-- Time Renarks -->
                 <v-col cols="12" sm="12" md="12" class="mt-n3">
                    <v-text-field
                    v-model 	  	="form.absence_remarks"
                    required
                    :rules          ="form.absence_remarks_rules"
                    label       	="Remarks *"
                    placeholder 	="Remarks"
                    id 			    ="absence_remarks"
                    @keyup.enter 	="Confirm('write','')"
                    :readonly       ="true"
                    >
                    </v-text-field>
                 </v-col>
                 <!-- Users -->
                 <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                        cb_type="users"
                        cb_url="apiListUsersShort"
                        cb_url_parameter="users_actived=Y&users_employeeflag=Y"
                        cb_title="Users"
                        cb_id="form_absence_usersid"
                        cb_desc="form_absence_usersname"
                        cb_rules=""
                        cb_id_readonly="readonly"
                        cb_desc_readonly="readonly"
                        cb_items_id="users_id"
                        cb_items_desc="users_name"
                        :cb_value_id="form.absence_usersid"
                        :cb_value_desc="form.absence_usersname"
                    >
                    </app-cb>
                 </v-col>
                  <!-- Users -->
                  <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                        cb_type="department"
                        cb_url="apiListDepartment"
                        cb_url_parameter="department_actived=Y"
                        cb_title="Department"
                        cb_id="absence_department"
                        cb_desc="absence_departmentdesc"
                        cb_rules=""
                        cb_id_readonly="readonly"
                        cb_desc_readonly="readonly"
                        cb_items_id="department_id"
                        cb_items_desc="department_desc"
                        :cb_value_id="form.absence_department"
                        :cb_value_desc="form.absence_departmentdesc"
                    >
                    </app-cb>
                 </v-col>
                  <!-- Branch -->
                <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                        cb_type="branch"
                        cb_url="apiListBranch"
                        cb_url_parameter="branch_actived=Y"
                        cb_title="Branch"
                        cb_id="absence_branch"
                        cb_desc="absence_branchdesc"
                        cb_rules=""
                        cb_id_readonly="readonly"
                        cb_desc_readonly="readonly"
                        cb_items_id="branch_id"
                        cb_items_desc="branch_desc"
                        :cb_value_id="form.absence_branch"
                        :cb_value_desc="form.absence_branchdesc"
                    >
                    </app-cb>
                 </v-col>
                 <v-col cols="12" sm="6" md="6" class="mt-n3">
                    <app-cb
                        cb_type="grade"
                        cb_url="apiListGrade"
                        cb_url_parameter="grade_actived=Y"
                        cb_title="Grade"
                        cb_id="absence_grade"
                        cb_desc="absence_grade"
                        cb_rules=""
                        cb_id_readonly="readonly"
                        cb_desc_readonly="readonly"
                        cb_items_id="grade_id"
                        cb_items_desc="grade_desc"
                        :cb_value_id="form.absence_grade"
                        :cb_value_desc="form.absence_gradedesc"
                    >
                    </app-cb>
                 </v-col>
              </v-row>
               </v-form>
               </v-card>
               </v-col>
               <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  class="mb-n5"
                >
                <v-card class="mb-4" outlined>
                    <v-card-title>{{form.absence_statusdesc}}</v-card-title>
                    <v-card-text>
                        <v-btn
                        color		="tertiary"
                        class		="white--text mb-2"
                        :disabled = "access.add === 0 ? true:false" 
                        depressed
                        @click 	= "Add" 
                        block
                      >
                        <v-icon dark>{{$vars.V('icon_add')}}</v-icon>{{$vars.V('txt_add')}}
                      </v-btn> 
                      &nbsp;
                      <v-btn
                        color		="primary"
                        class		="white--text mb-2"
                        :disabled = "access.add === 0 || access.edit === 0 ? true:false"
                        depressed
                        @click 	= "Confirm('write','')" 
                        block
                      >
                        <v-icon dark>{{$vars.V('icon_save')}}</v-icon>{{$vars.V('txt_save')}}
                      </v-btn>
                      <div class="text-center">
                      <v-btn
                        color		="green"
                        class		="white--text mr-2"
                        :disabled   ="access.add === 0 || access.edit === 0 ? true:false"
                        depressed
                        @click 	="Confirm('write','')" 
                        v-if="url_type === 'edit' && form.absence_status === 'R'"
                      >
                        <v-icon dark>{{$vars.V('icon_approve')}}</v-icon>{{$vars.V('txt_approve')}}
                      </v-btn>
                      <v-btn
                        color		="red"
                        class		="white--text"
                        :disabled   ="access.add === 0 || access.edit === 0 ? true:false"
                        depressed
                        @click 	="Confirm('write','')" 
                        v-if="url_type === 'edit' && form.absence_status === 'R'"
                      >
                        <v-icon dark>{{$vars.V('icon_reject')}}</v-icon>{{$vars.V('txt_reject')}}
                      </v-btn>
                    </div>
                      <v-expansion-panels v-if="url_type === 'edit'" class="mt-2">
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Timeline</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <template>
                              <v-timeline dense clipped>
                                <v-timeline-item
                                  class="mb-4"
                                  :color="form.absence_cuser?'green':'red'"
                                  icon-color="green lighten-2"
                                  small
                                >
                                  <v-row justify="space-between">
                                    <v-col cols="7">
                                      Created
                                      <br />
                                      <v-chip
                                        class="white--text ml-0"
                                        color="info"
                                        label
                                        small
                                      >
                                        {{ this.form.absence_cuser }}
                                      </v-chip>
                                    </v-col>
                                    <v-col class="text-right" cols="5">
                                      {{
                                        this.$functions.FormatDateTime(
                                          this.form.absence_cdate
                                        )
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
    
                                <v-timeline-item
                                  class="mb-4"
                                  :color="form.absence_luser?'green':'red'"
                                  icon-color="green lighten-2"
                                  small
                                >
                                  <v-row justify="space-between">
                                    <v-col cols="7">
                                      Updated
                                      <br />
                                      <v-chip
                                        class="white--text ml-0"
                                        color="info"
                                        label
                                        small
                                      >
                                        {{ this.form.absence_luser }}
                                      </v-chip>
                                    </v-col>
                                    <v-col class="text-right" cols="5">
                                      {{
                                        this.$functions.FormatDateTime(
                                          this.form.absence_ldate
                                        )
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                                <v-timeline-item
                                  class="mb-4"
                                  :color="form.absence_leaderuser?'green':'red'"
                                  icon-color="green lighten-2"
                                  small
                                >
                                  <v-row justify="space-between">
                                    <v-col cols="7">
                                      Leader
                                      <br />
                                      <v-chip
                                        class="white--text ml-0"
                                        color="info"
                                        label
                                        small
                                      >
                                        {{ this.form.absence_leaderuser }}
                                      </v-chip>
                                    </v-col>
                                    <v-col class="text-right" cols="5">
                                      {{
                                        this.$functions.FormatDateTime(
                                          this.form.absence_leaderdate
                                        )
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                                <v-timeline-item
                                  class="mb-4"
                                  :color="form.absence_hruser?'green':'red'"
                                  icon-color="green lighten-2"
                                  small
                                >
                                  <v-row justify="space-between">
                                    <v-col cols="7">
                                      HR
                                      <br />
                                      <v-chip
                                        class="white--text ml-0"
                                        color="info"
                                        label
                                        small
                                      >
                                        {{ this.form.absence_hruser }}
                                      </v-chip>
                                    </v-col>
                                    <v-col class="text-right" cols="5">
                                      {{
                                        this.$functions.FormatDateTime(
                                          this.form.absence_hrdate
                                        )
                                      }}
                                    </v-col>
                                  </v-row>
                                </v-timeline-item>
                              </v-timeline>
                            </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card-text>
                </v-card>
               </v-col>
               </v-row>
                </v-tab-item>
                <v-tab-item
                  value="tab-list"
              >
               <!-- Advanced Search -->
               <v-dialog
               v-model="dialog.search"
               max-width="590"
               transition="dialog-bottom-transition"
               persistent
                >
                    <v-card>
                    <v-toolbar dark color="primary" dense>
                        <v-btn icon dark @click="dialog.search = false">
                        <v-icon>{{$vars.V('icon_close')}}</v-icon>
                        </v-btn>
                        <v-toolbar-title>Advanced Search</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="Reset">
                        <v-icon>clear_all</v-icon>
                        </v-btn>
                        <v-btn icon dark @click="List">
                        <v-icon>{{$vars.V('icon_search')}}</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <template>
                        <v-row>
                            <!-- ID -->
                            <v-col cols="12" sm="6" md="6">
                                <v-dialog
                                ref                 ="dialog_absence_sdate"
                                v-model             ="modal.absence_sdate"
                                :return-value.sync  ="find.absence_sdate"
                                width               ="290px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model             ="find.absence_sdate"
                                    label               ="Start Date"
                                    prepend-inner-icon  ="event"
                                    background-color    ="date"
                                    v-on                ="on"
                                    v-mask              ="'####-##-##'"
                                    placeholder         ="YYYY-MM-DD"
                                    id                  ="find_absence_sdate"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="find.absence_sdate" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal.absence_sdate = false">Cancel</v-btn>
                                <v-btn
                                    text
                                    color   ="primary"
                                    @click  ="$refs.dialog_absence_sdate.save(find.absence_sdate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            </v-col>
                            <!-- Name -->
                            <v-col cols="12" sm="6" md="6">
                                <v-dialog
                                ref                 ="dialog_absence_edate"
                                v-model             ="modal.absence_edate"
                                :return-value.sync  ="find.absence_edate"
                                width               ="290px"
                                persistent
                            >
                                <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model             ="find.absence_edate"
                                    label               ="End Date"
                                    prepend-inner-icon  ="event"
                                    background-color    ="date"
                                    v-on                ="on"
                                    v-mask              ="'####-##-##'"
                                    placeholder         ="YYYY-MM-DD"
                                    id                  ="find_absence_edate"
                                ></v-text-field>
                                </template>
                                <v-date-picker v-model="find.absence_edate" :min="find.absence_sdate" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal.absence_edate = false">Cancel</v-btn>
                                <v-btn
                                    text
                                    color   ="primary"
                                    @click  ="$refs.dialog_absence_edate.save(find.absence_edate)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n3">
                                <app-cb
                                    cb_type="users"
                                    cb_url="apiListUsersShort"
                                    cb_url_parameter="users_actived=Y&users_employeeflag=Y"
                                    cb_title="Users"
                                    cb_id="find_absence_usersid"
                                    cb_desc="find_absence_usersname"
                                    cb_rules=""
                                    cb_desc_readonly="readonly"
                                    cb_items_id="users_id"
                                    cb_items_desc="users_name"
                                    :cb_value_id="find.absence_usersid"
                                    :cb_value_desc="find.absence_usersname"
                                    @clicked="GetFindUsers"
                                >
                                </app-cb>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="mt-n3">
                            <app-cb
                                cb_type="code"
                                cb_url="apiListCode"
                                cb_url_parameter="code_actived=Y&code_group=AbsenceSTATUS"
                                cb_title="Status"
                                cb_id="find_absence_status"
                                cb_desc="find_absence_statusdesc"
                                cb_rules=""
                                cb_desc_readonly="readonly"
                                cb_items_id="code_id"
                                cb_items_desc="code_desc"
                                :cb_value_id="find.absence_status"
                                :cb_value_desc="find.absence_statusdesc"
                                @clicked="GetFindStatus"
                            >
                            </app-cb>
                        </v-col>
                        </v-row>
                        </template>
                    </v-card-text>
                    </v-card>
                </v-dialog>
                <!-- Advanced Search -->
              <template>
                <v-card-title 
                        class="ml-n4"
                    >
                    <v-btn 
                    :color 	="selected.length === items.length ? 'secondary':''"
                    @click	="SelectAllPage"
                    class	="mr-2"
                    :title="$vars.V('txt_select_all_page')"
                    small
                    >{{$vars.V('txt_select_all_page')}}
                    </v-btn>
                    <v-btn small class="primary white--text mr-2" v-if="items.length > 0">
                    <vue-download-excel :data="items" worksheet="Data Download" name="data-download.xls">
                    {{$vars.V('txt_download_to_excel')}}
                    </vue-download-excel>
                    </v-btn>
                    <v-btn 
                        color     = "error"
                        title 	  = "delete selected"
                        @click 	  = "Confirm('multidelete','')"
                        class	  = "mr-2"
                        v-if  	  = "selected.length > 0"
                        :disabled = "access.delete === 0 ? true:false"
                        small>
                        <v-icon>{{$vars.V('icon_delete')}}</v-icon>
                    </v-btn>
                    <v-btn 
                        color  = "primary"
                        title  = "search"
                        v-if   = "btn_search === false"
                        @click="OpenSearch"
                        small>
                        <v-icon>{{$vars.V('icon_search')}}</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                    v-model="search"
                    append-icon="search"
                    @click:append="btn_search = false"
                    label="Search"
                    single-line
                    hide-details
                    v-if ="btn_search"
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                  :value="selected"
                  :headers="headers"
                  :items="items"
                  :search="search"
                  @input="Check($event)"
                  item-key="absence_id"
                  show-select
                  class="elevation-1"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'first_page',
                    lastIcon: 'last_page',
                    prevIcon: 'keyboard_arrow_left',
                    nextIcon: 'keyboard_arrow_right'
                  }"
                  dense
                  :loading 	= "loading.list"
                  loading-text="Loading... Please wait"
                >
                <template v-slot:item.absence_usersavatar="{ item }">
                    <v-avatar size="60">
                      <img :src="item.absence_usersavatarurl" :alt="item.absence_usersavatar">
                    </v-avatar>
                  </template>
                  <template v-slot:item.absence_timein="{ item }">
                    {{ item.absence_timein }} - {{ item.absence_timeout }} ({{ item.absence_timediff }})
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-icon
                      small
                      class	  ="mr-2"
                      @click	  ="Edit(item.absence_id)"
                      :disabled = "access.edit === 0 ? true:false"
                      title 	  = "edit"
                    >
                      mouse
                </v-icon>|
                    <v-icon
                      small
                      @click	  ="Confirm('delete',item.absence_id)"
                      :disabled = "access.delete === 0 ? true:false"
                      title 	  = "delete"
                    >
                      delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
          </v-tab-item>
        </v-tabs>
      <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
          <v-card>
              <v-toolbar dark color="fourth" dense>
              <v-btn icon dark @click="remarks.dialog = false">
                  <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Error Messages</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-2 ml-n2">
                <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
              </v-card-text>
          </v-card>
      </v-dialog>
      <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
          <v-card>
              <v-toolbar dark color="fourth">
              <v-btn icon dark @click="remarks.dialog = false">
                  <v-icon>{{$vars.V('icon_close')}}</v-icon>
              </v-btn>
              <v-toolbar-title>Error Messages</v-toolbar-title>
              </v-toolbar>
              <v-card-text class="mt-2 ml-n2">
                <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
              </v-card-text>
          </v-card>
      </v-dialog>
      <v-snackbar
        v-model	= "snackbar.dialog"
        :timeout	= "snackbar.timeout"
        color		= "fourth"
        rounded	= "pill"
        top
        style 	= "z-index: 9999;"
        >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
        <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
          </template>
      </v-snackbar>
      <v-dialog v-model="confirm.dialog" :max-width="Variable('confirm',0)" persistent>
          <v-card :color="Variable('confirm',4)">
              <v-card-title :class="Variable('confirm',3)">{{confirm.title}}?</v-card-title>
              <v-card-text>
                  
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="dialog" :color="Variable('confirm',1)"
                      :disabled   = "confirm.text === 'Ok' ? false:true"
                    @click      = "Loading">{{confirm.text}}</v-btn>
                  <v-btn :color="Variable('confirm',2)" @click="confirm.dialog=false">Close</v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>
      <!-- Dialog Users -->
      <app-users :visible="dialog.users" :users_id="form.display_users" @close="dialog.users=false"></app-users>
    </div>
  </template>
  <script>
  export default {
        data: () => ({
          url_type: 'add',
          cont: false,
            access: {
                read: 0,
                add: 0,
                edit: 0,
                delete: 0,
            },
          maxinput: {
              id:	15,
              desc:150,
          },
          snackbar: {
              dialog: false,
              color: 'success',
              text: '',
              timeout: 3000
          },
          confirm: {
              dialog: false,
              title: '',
              text: 'Ok',
          },
          selected: [],
          search: '',
          headers: [
            {
              text: 'ID',
              align: 'left',
              value: 'absence_id',
            },
            { text: 'Avatar', value: 'absence_usersavatar', align: 'center' },
            { text: 'Users', value: 'absence_usersid' },
            { text: 'Date', value: 'absence_date' },
            { text: 'Time', value: 'absence_timein' },
            { text: 'Status', value: 'absence_statusdesc' },
            { text: 'Actions', value: 'action', sortable: false }
          ],
          remarks: {
              feedback:'',
              dialog: false,
          },
          items: [],
          accessmenu: [],
          loading: {
            page: true,
            list : false
          },
          btn_search: false,
          form: {
              absence_id: '',
              absence_id_rules: [v => !!v || 'Please fill ID'],
              absence_id_readonly: true,
              absence_date: '',
              absence_date_rules: [v => !!v || 'Please fill Date'],
              absence_timein: '',
              absence_timein_rules: [v => !!v || 'Please fill Time In'],
              absence_timeout: '',
              absence_timeout_rules: [v => !!v || 'Please fill Time Out'],
              absence_usersid: '',
              absence_usersid_rules: [v => !!v || 'Please fill Users ID'],
              absence_status: '',
              absence_statusdesc: '',
              absence_cdate: '',
              absence_cuser: '',
              absence_ldate: '',
              absence_luser: '',
              absence_leaderdate: '',
              absence_leaderuser: '',
              absence_hrdate: '',
              absence_hruser: '',
              display_users: '',
          },
          find: {
            absence_sdate :'',
            absence_edate :'',
            absence_status :'',
            absence_statusdesc :'',
            absence_usersid :'',
            absence_usersname :'',
          },
          modal: {
            absence_sdate: false,
            absence_edate: false,
          },
          dialog: {
              users: false,
              search: false
          }
      }),
        created () {
          this.FirstLoad()
      },
        methods: {
            FirstLoad () {
              //this function use for validating the screen with user privilleges
                this.Access()
          },
          //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
          Access () {
              let modul = 'absence'
              let formdata = {
                  menu_id: modul,
                  users_id: this.$functions.UsersID(),
                  order_by: 'menu_id',
                  order_type: 'ASC',
                  limit: this.limit,
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListMenuUser'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let priv = response.data
                  if (priv.length > 0) {
                      if (priv[0].feedback !== 'Y') {
                          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                          this.remarks.dialog = true
                          this.remarks.feedback = priv[0].feedback
                      } else {
                          this.access.read 	= priv[0].usersmenu_read
                          this.access.add  	= priv[0].usersmenu_add
                          this.access.edit    = priv[0].usersmenu_edit
                          this.access.delete  = priv[0].usersmenu_delete
                          if (priv[0].usersmenu_read === 0) {
                              this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                          }
                      }
                  } else {
                      this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
                  }
                  this.loading.page = false
              })
              .catch(e => {
                  this.SnackBar(true, 'error', e, 0)
                  this.loading.page = false
              })
          },
          SelectAllPage () {
            this.selected = this.selected === this.items ? [] : this.items
          },
          Check (value) {
            this.selected = value
          },
          //Function for get all data from table channel with API in mod_users.go
          List () {
              this.loading.list = true
              let formdata = {
                  absence_id: '',
                  absence_date: '',
                  absence_sdate: this.find.absence_sdate,
                  absence_edate: this.find.absence_edate,
                  absence_status: this.find.absence_status,
                  absence_usersid: this.find.absence_usersid,
                  order_by: 'absence_id',
                  order_type: 'ASC',
                  limit: this.limit,
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback !== 'Y') {
                          this.SnackBar(true, 'error', feedback[0].feedback, 0)
                      } else {
                          this.items = feedback
                      }
                  } else {
                      this.items = feedback
                      this.SnackBar(true, 'error', this.$functions.NoData(), 0)
                  }
                  this.loading.list = false
              })
              .catch(e => {
                  this.SnackBar(true, 'error', e, 3000)
                  this.loading.list = false
              })
          },
          //Pop Up message for validating (Ok or Cancel) your proccess
            Confirm (flag, id) {
                if (flag === 'write') {
                    if (this.$refs.form_absence.validate()) {
                        this.confirm.dialog   = true
                        this.confirm.title    = 'Save'
                        setTimeout(function(){
                      document.getElementById('dialog').focus()
                      }, 500)
                    }
                } else if (flag === 'delete') {
                    this.url_type 		  = 'delete'
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Delete `'+ id + '`'
                    this.absence_id 	  = id
                    setTimeout(function(){
                  document.getElementById('dialog').focus()
                  }, 500)
                } else if (flag === 'multidelete') {
                    this.url_type 		  = 'multidelete'
                    this.confirm.dialog   = true
                    this.confirm.title    = 'Delete `'+ this.selected.length + '` data'
                    setTimeout(function(){
                  document.getElementById('dialog').focus()
                  }, 500)
                }
            },
            
          //Function to request insert data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteAbsence with url_type = "add") 
            Add () {
                this.url_type 		 		= 'add'
                this.form.absence_id 	 	= ''
                this.form.absence_date 	= ''
                this.form.absence_timein 	= ''
                this.form.absence_timeout 	= ''
                this.form.absence_id_readonly = false
                setTimeout(function(){
                  document.getElementById('absence_id').focus()
              }, 500)
          },
          //Function to request update data to table channel 'The API will validating your data, include the user who process the data' (go to APIWriteAbsence with url_type = "edit") 
          Edit (id) {
              this.loading.list = true
              let formdata = {
                  absence_id: id,
                  absence_date: '',
                  order_by: 'absence_id',
                  order_type: 'ASC',
                  limit: this.limit,
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiListAbsence'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                          document.getElementById('tabform').click()
                          this.url_type 			            = 'edit'
                          this.form.absence_id   	            = feedback[0].absence_id
                          this.form.absence_date 	            = feedback[0].absence_date
                          this.form.absence_timein 	        = feedback[0].absence_timein
                          this.form.absence_timeout 	        = feedback[0].absence_timeout
                          this.form.absence_remarks 	        = feedback[0].absence_remarks
                          this.form.absence_usersid 	        = feedback[0].absence_usersid
                          this.form.absence_usersname 	        = feedback[0].absence_usersname
                          this.form.absence_department 	    = feedback[0].absence_department
                          this.form.absence_departmentdesc 	= feedback[0].absence_departmentdesc
                          this.form.absence_branch 	        = feedback[0].absence_branch
                          this.form.absence_branchdesc 	    = feedback[0].absence_branchdesc
                          this.form.absence_grade 	            = feedback[0].absence_grade
                          this.form.absence_gradedesc 	        = feedback[0].absence_gradedesc
                          this.form.absence_status 	        = feedback[0].absence_status
                          this.form.absence_statusdesc	        = feedback[0].absence_statusdesc
                          this.form.absence_cdate		        = feedback[0].absence_cdate
                          this.form.absence_cuser		        = feedback[0].absence_cuser
                          this.form.absence_ldate		        = feedback[0].absence_ldate
                          this.form.absence_luser		        = feedback[0].absence_luser
                          this.form.absence_id_readonly  = true
                          setTimeout(function(){
                              document.getElementById('absence_date').focus()
                          }, 500)
                        } else {
                          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                          this.remarks.dialog = true
                          this.remarks.feedback = feedback[0].feedback
                      }
                  } else {
                      this.SnackBar(true, 'error', feedback, 0)
                  }
                  this.loading.list = false
              })
              .catch(e => {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog = true
                  this.remarks.feedback = e
                  this.loading.list = false
              })
          },
          Delete (id) {
              let formdata = {
                  url_type: 'delete',
                  absence_id: id
              }
                let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiWriteAbsence'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                          this.SnackBar(true, 'success', 'Delete Success', 3000)
                          this.confirm.dialog  	 = false
                          this.confirm.text 	 	 = 'Ok'
                          this.selected 			 = []
                          this.List()
                        } else {
                          this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                          this.remarks.dialog = true
                          this.remarks.feedback = feedback[0].feedback
                      }
                  }
              })
              .catch(e => {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog = true
                  this.remarks.feedback = e
              })
            },
            MultiProcess () {
              let multiData = JSON.stringify(this.selected)
              let formdata = {
                  url_type: this.url_type,
                  multi_data: multiData,
              }
              let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiWriteAbsence'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback[0].feedback === 'Y') {
                      let feed
                      if (feedback[0].feedback_failed == 0){
                          feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                      } else {
                          this.remarks.feedback = feedback[0].feedback_failed_remarks
                          feed = `Success : ${feedback[0].feedback_success}, Failed : ${feedback[0].feedback_failed}`
                          this.remarks.dialog = true
                      }
                      this.SnackBar(true, 'success', feed, 0)
                      this.confirm.dialog  	 = false
                      this.confirm.text 	 	 = 'Ok'
                      this.selected 			 = []
                      this.List()
                  } else {
                      this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                      this.remarks.dialog = true
                      this.remarks.feedback = feedback[0].feedback
                  }
              })
              .catch(e => {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog = true
                  this.remarks.feedback = e
              })
            },
            Loading () {
                this.confirm.text = 'Loading...'
                if (this.url_type === 'add' || this.url_type === 'edit') {
                    this.Write()
                } else if (this.url_type === 'delete') {
                    this.Delete(this.absence_id)
                } else if (this.url_type === 'multidelete') {
                    this.MultiProcess('delete')
                }
            },
          Write () {
                let formdata = {
                  url_type: this.url_type,
                  absence_id: this.form.absence_id,
                  absence_date: this.form.absence_date,
              }
                let param 	   = this.$functions.ParamPOST(formdata)
              this.$axios.post(this.$functions.UrlPOST('apiWriteAbsence'),param,{
                  headers: {
                      'Content-Type': 'text/plain; charset=ISO-8859-1'
                  }
              })
              .then(response => {
                  let feedback = response.data
                  if (feedback.length > 0) {
                      if (feedback[0].feedback === 'Y') {
                          this.SnackBar(true, 'success', 'Save Success', 3000)
                          this.confirm.dialog  	 = false
                          this.confirm.text 	 	 = 'Ok'
                            if (this.url_type === 'add') {
                              this.form.absence_cuser  = feedback[0].feedback_users_id
                                this.form.absence_cdate  = feedback[0].feedback_users_date
                            }
                            this.form.absence_luser  = feedback[0].feedback_users_id
                            this.form.absence_ldate  = feedback[0].feedback_users_date
                            this.url_type 			 = 'edit'
                        } else {
                            this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                          this.remarks.dialog = true
                          this.remarks.feedback = feedback[0].feedback
                      }
                  } else {
                      this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                      this.remarks.dialog = true
                      this.remarks.feedback = feedback
                  }
              })
              .catch(e => {
                  this.SnackBar(true, 'error', this.$functions.ErrorCode(), 0)
                  this.remarks.dialog = true
                  this.remarks.feedback = e
              })
          },
            SnackBar (dialog, color, text, timeout) {
            this.snackbar = {
                dialog: dialog,
                color: color,
                text: text,
                timeout: timeout
            }
            if (color === 'error') {
                this.confirm.dialog 	 = false
                this.confirm.text 	 	 = 'Ok'
            }
          },
          ShowUsers (id) {
              this.dialog.users = true
              this.form.display_users = id
          },
          Variable (flag, position) {
              return this.$functions.Variable(flag,position)
          },
          GetFindStatus (value) {
            var split = this.$functions.Split(value, "|")
            this.find.absence_status       = split[0]
            this.find.absence_statusdesc  = split[1]
          },
          GetFindUsers (value) {
            var split = this.$functions.Split(value, "|")
            this.find.absence_usersid       = split[0]
            this.find.absence_usersname  = split[1]
          },
          Reset() {
            this.find.absence_sdate = "";
            this.find.absence_edate = "";
            this.find.absence_status = "";
            this.find.absence_statusdesc = "";
            this.find.absence_usersid = "";
            this.find.absence_usersname = "";
            setTimeout(function () {
                document.getElementById("find_absence_sdate").focus();
            }, 500);
        },
        OpenSearch() {
            this.dialog.search = true;
            this.btn_search = false;
            setTimeout(function () {
                document.getElementById("find_absence_sdate").focus();
            }, 500);
        },
        Search() {
            this.btn_search = false
            this.dialog.search = false
            this.List()
        },
      }
  }
  </script>